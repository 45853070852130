/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-nocheck
/* eslint-disable prefer-const */
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState, useReducer } from 'react';

import { Accordion, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DeclarePermissions } from '../01EducatorTab/components/DeclareYourPermission';
import { PersonalPortfolioCardForm } from '../../Form-components/PersonalPortfolioCardForm/PersonalPortfolioCard';
import { DataFromReviewers } from '../01EducatorTab/FormDataFromReviewers';
import { useFilterToggles } from './hooks/useFilterToggles';

const isBrowser = typeof window !== 'undefined';

function UsersPermissions({
  formLabelsFromPrismic,
  firstFormVars,
  secondFormVars,
  firestore,
  handleClose,
  lang,
  defaultLanguage,
}) {
  const [show, setShow] = useState(false);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  // fetch if exist Firebase firestore(database) data
  const [accessLevelsToggle, setAccessLevelsToggle] = useState(false);
  const [statusToggle, setStatusToggle] = useState(false);
  const [othersToggle, setOthersToggle] = useState(false);
  const [isVisibleDataFromReviewersForSecretary, setIsVisibleDataFromReviewersForSecretary] =
    useState(false);
  const [isEditPersonalPortfolioCard, setIsEditPersonalPortfolioCard] = useState(false);

  const [usersList, setUsersList] = useState([]);
  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;

  const { filterState, filterDispatch, isFilterInUse } = useFilterToggles();

  const filteredUsers = usersList.filter(
    user =>
      (!filterState.registered || user.currentStatus === 'Registered') &&
      (!filterState.monitoringCommittee || user.monitoring_committee_member) &&
      (!filterState.qaCommittee || user.qa_committee_member) &&
      (!filterState.expertCommittee || user.expert_committee_member) &&
      (!filterState.contactPersonInstitutionAccess || user.cp_any_institution) &&
      (!filterState.contactPersonAccreditationAgencyAccess || user.cp_acc_agency) &&
      (!filterState.portfolioUnderReview || user.currentStatus === 'Portfolio under review') &&
      (!filterState.portfolioReviewed || user.currentStatus === 'Portfolio reviewed') &&
      (!filterState.academicCommittee || user.academic_committee_member),
  );

  const usersListToUse = isFilterInUse ? filteredUsers : usersList;

  useEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firestore
          .collection('usersCollection')
          .orderBy('initial_registration_date', 'desc')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            setUsersList(listUsers);
          });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [isBrowser]);

  return (
    <div>
      <h1>
        List of all users
        <span
          style={{
            fontWeight: '400',
            textAlign: 'center',
            padding: '5px auto',

            margin: '0 15px',
            overflow: 'hidden',
            width: '80px',
          }}
        >
          {usersListToUse.length}
        </span>
      </h1>

      <div className="d-flex flex-row justify-content-between mb-2 mt-2">
        <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
          back
        </button>
        <Button variant="primary" onClick={handleShowModal}>
          Filters
        </Button>
      </div>
      <Modal centered keyboard={false} show={show} onHide={handleCloseModal}>
        <Modal.Header className=" w-100">
          <Modal.Title>Apply Filtering</Modal.Title>{' '}
          <Button size="sm" variant="primary" onClick={() => filterDispatch({ type: 'Clear All' })}>
            Clear All Filters
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: 'none' }}>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Academic Committee' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-1"
                checked={filterState.academicCommittee || false}
              />
              Academic Committee{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Monitoring Committee' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-2"
                checked={filterState.monitoringCommittee || false}
              />
              Monitoring Committee{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'QA Committee' });
              }}
            >
              <input type="checkbox" id="checkbox-3" checked={filterState.qaCommittee || false} />
              QA Committee{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Expert Committee' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-4"
                checked={filterState.expertCommittee || false}
              />
              Expert Committee
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Contact Person Institution Access' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-6"
                checked={filterState.contactPersonInstitutionAccess || false}
              />
              Contact Person Institution Access{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Contact Person Accreditation Agency Access' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-7"
                checked={filterState.contactPersonAccreditationAgencyAccess || false}
              />
              Contact Person Accreditation Agency Access{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Portfolio Under Review' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-8"
                checked={filterState.portfolioUnderReview || false}
              />
              Portfolio Under Review{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Portfolio Reviewed' });
              }}
            >
              <input
                type="checkbox"
                id="checkbox-9"
                checked={filterState.portfolioReviewed || false}
              />
              Portfolio Reviewed{' '}
            </li>
            <li
              className="filter-li"
              onClick={() => {
                filterDispatch({ type: 'Registered' });
              }}
            >
              <input type="checkbox" id="checkbox-10" checked={filterState.registered || false} />
              Registered
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* Accordion with users List */}
      <Accordion flush>
        {usersListToUse.map((currentUserProfileData, key) => {
          let {
            full_name,
            initial_registration_date,
            id,
            uid,
            educator,
            cp_any_institution,
            cp_acc_agency,
            academic_committee_member,
            qa_committee_member,
            monitoring_committee_member,
            expert_committee_member,
            president,
            currentStatus,
            showDataFromReviewers,
            email,
          } = currentUserProfileData;

          const status = currentUserProfileData?.activityStatus || false;
          const currentInstitution =
            currentUserProfileData.higher_education_institution ||
            currentUserProfileData.data?.university ||
            currentUserProfileData.organization;
          const dateInMilliseconds = new Date(initial_registration_date).toLocaleDateString();
          return (
            <Accordion.Item key={uid + key} eventKey={key}>
              <Accordion.Header>
                <div>
                  <div>
                    <span
                      style={{
                        fontSize: '10px',
                        fontWeight: '400',
                        textAlign: 'center',
                        padding: '5px auto',
                        borderRight: '2px gray solid',
                        borderLeft: '2px gray solid',
                        margin: '0 15px 0 0',
                        overflow: 'hidden',
                        width: '80px',
                      }}
                    >
                      {dateInMilliseconds}
                    </span>
                    {full_name}

                    <span
                      style={{
                        fontSize: '10px',
                        fontWeight: '400',
                        textAlign: 'center',
                        padding: '0px 15px',
                        letterSpacing: '2px',
                        borderLeft: '2px gray solid',
                        margin: '0 15px',
                        overflow: 'hidden',
                      }}
                    >
                      {email}
                    </span>
                  </div>
                  <div>
                    {' '}
                    <span
                      style={{
                        fontSize: '10px',
                        fontWeight: '400',
                        textAlign: 'center',
                        padding: '5px auto',
                        margin: '0 15px 0 0',
                        overflow: 'hidden',
                        width: '80px',
                      }}
                    >
                      {currentInstitution}
                    </span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <span>
                  {' UID >>>  '}
                  {uid}
                </span>
                <br />
                {/* Set User Type Button */}
                <div className="d-flex justify-content-start gap-2">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setAccessLevelsToggle(!accessLevelsToggle);
                    }}
                  >
                    Set User Type
                  </button>
                </div>
                <br />
                {/* Set Status Button */}

                <div className="d-flex justify-content-start gap-2">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setStatusToggle(!statusToggle);
                    }}
                  >
                    Set Status
                  </button>
                </div>
                <br />
                {/* Others Button */}
                <div className="d-flex justify-content-start gap-2">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setOthersToggle(!othersToggle);
                    }}
                  >
                    Others
                  </button>
                </div>
                {statusToggle && (
                  <div className="access-level-wrapper">
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            currentStatus: 'Portfolio not submitted',
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${
                        currentStatus === 'Portfolio not submitted' ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Portfolio not submitted
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore
                            .collection('usersCollection')
                            .doc(id)
                            .update({ currentStatus: 'Portfolio under review' });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${
                        currentStatus === 'Portfolio under review' ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Portfolio under review
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            currentStatus: 'Portfolio reviewed',
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${
                        currentStatus === 'Portfolio reviewed' ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Portfolio reviewed
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            currentStatus: 'Not registered',
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${
                        currentStatus === 'Not registered' ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Not registered
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            currentStatus: 'Registered',
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${
                        currentStatus === 'Registered' ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Registered
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            activityStatus: !status,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${status ? 'btn-success' : 'btn-warning'}`}
                    >
                      {status ? 'Active' : 'Not Active'}
                    </button>
                  </div>
                )}
                {othersToggle && (
                  <div className="access-level-wrapper">
                    <a
                      // to={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${userUnderReview.id}`}
                      href={`${localLinkResolver}/edit-mcm-forms?userId=${currentUserProfileData.id}`}
                      className="btn btn-sm btn-outline-dark mx-2"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      Edit Reviewers Data
                    </a>
                    <a
                      // to={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${userUnderReview.id}`}
                      href={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${currentUserProfileData.id}`}
                      className="btn btn-sm btn-outline-dark mx-2"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      Full Portfolio of the Candidate
                    </a>
                    <button
                      type="button"
                      onClick={async () => {
                        setIsVisibleDataFromReviewersForSecretary(
                          !isVisibleDataFromReviewersForSecretary,
                        );
                      }}
                      className={`btn ${
                        isVisibleDataFromReviewersForSecretary ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Check Data From Reviewers
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        setIsEditPersonalPortfolioCard(!isEditPersonalPortfolioCard);
                      }}
                      className={`btn ${
                        isEditPersonalPortfolioCard ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Edit Personal Portfolio Card
                    </button>
                    {/* showDataFromReviewers */}
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          firestore
                            .collection('usersCollection')
                            .doc(id)
                            .update({
                              showDataFromReviewers: !showDataFromReviewers,
                            })
                            .then(() => {
                              // eslint-disable-next-line no-alert
                              alert(
                                `Review results is ${
                                  showDataFromReviewers ? 'not ' : 'now '
                                } visible for the user`,
                              );
                              // alert('Review results is now visible for the user');
                            });
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      className={`btn ${showDataFromReviewers ? 'btn-success' : 'btn-warning'}`}
                    >
                      {showDataFromReviewers ? 'Hide' : 'Show'} Data From Reviewers
                    </button>

                    {/* <button type='button'
                      onClick={async () => {
                        try {
                          firestore
                            .collection('usersCollection')
                            .doc(currentUserProfileData.id)
                            .update({
                              isEmailVisible: !currentUserProfileData.isEmailVisible,
                            });

                          currentUserProfileData.isEmailVisible
                            ? alert('E-mail is not visible for the Enter Register')
                            : alert('E-mail is now visible for the Enter Register');
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      className={`btn w-50 ${
                        currentUserProfileData.isEmailVisible ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      {currentUserProfileData.isEmailVisible ? 'Hide' : 'Show'} e-mail
                    </button>
                    <button type='button'
                      onClick={async () => {
                        try {
                          firestore
                            .collection('usersCollection')
                            .doc(currentUserProfileData.id)
                            .update({
                              isUniversityVisible: !currentUserProfileData.isUniversityVisible,
                            });

                          currentUserProfileData.isUniversityVisible
                            ? alert('University is not visible for the Enter Register')
                            : alert('University is now visible for the Enter Register');
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      className={`btn w-50 ${
                        currentUserProfileData.isUniversityVisible ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      {currentUserProfileData.isUniversityVisible ? 'Hide' : 'Show'} University
                    </button> */}

                    <button
                      type="button"
                      onClick={async () => {
                        firebase
                          .auth()
                          .getUser(currentUserProfileData.uid)
                          .then(userRecord => {
                            // See the UserRecord reference doc for the contents of userRecord.
                            console.info(`Successfully fetched user data: ${userRecord.toJSON()}`);
                          })
                          .catch(error => {
                            console.info('Error fetching user data:', error);
                          });
                      }}
                      className="btn w-50  btn-warning"
                    >
                      Reset user&apos;s password
                    </button>
                    <div className=" d-flex flex-column gap-3 w-100">
                      <DeclarePermissions
                        firestore={firestore}
                        currentUserProfileData={currentUserProfileData}
                        isSecretary
                      />
                    </div>
                  </div>
                )}
                {isVisibleDataFromReviewersForSecretary && (
                  <DataFromReviewers
                    currentUserProfileData={currentUserProfileData}
                    firstFormVars={firstFormVars}
                    secondFormVars={secondFormVars}
                  />
                )}
                {isEditPersonalPortfolioCard && (
                  <PersonalPortfolioCardForm
                    userUnderReview={currentUserProfileData}
                    applicantID={currentUserProfileData.id}
                    formLabelsFromPrismic={formLabelsFromPrismic}
                  />
                )}
                {accessLevelsToggle && (
                  <div className="access-level-wrapper">
                    <button
                      type="button"
                      onClick={async () => {
                        educator = !educator;
                        try {
                          firestore.collection('usersCollection').doc(id).update({ educator });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${educator ? 'btn-success' : 'btn-warning'}`}
                    >
                      Educator Access
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        cp_any_institution = !cp_any_institution;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            cp_any_institution,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${cp_any_institution ? 'btn-success' : 'btn-warning'}`}
                    >
                      Contact Person Institution Access
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        cp_acc_agency = !cp_acc_agency;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            cp_acc_agency,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${cp_acc_agency ? 'btn-success' : 'btn-warning'}`}
                    >
                      Contact Person Accreditation Agency Access
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        academic_committee_member = !academic_committee_member;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            academic_committee_member,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${academic_committee_member ? 'btn-success' : 'btn-warning'}`}
                    >
                      Academic Committee Member Access {academic_committee_member}
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        qa_committee_member = !qa_committee_member;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            qa_committee_member,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${qa_committee_member ? 'btn-success' : 'btn-warning'}`}
                    >
                      QA Committee Member Access
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        monitoring_committee_member = !monitoring_committee_member;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            monitoring_committee_member,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${
                        monitoring_committee_member ? 'btn-success' : 'btn-warning'
                      }`}
                    >
                      Monitoring Committee Member Access
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        expert_committee_member = !expert_committee_member;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            expert_committee_member,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${expert_committee_member ? 'btn-success' : 'btn-warning'}`}
                    >
                      Expert Committee Member Access
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        president = !president;
                        try {
                          firestore.collection('usersCollection').doc(id).update({
                            president,
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      className={`btn ${president ? 'btn-success' : 'btn-warning'}`}
                    >
                      Governing Board Access
                    </button>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <div className="d-flex flex-row mt-2">
        <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
          back
        </button>
      </div>
    </div>
  );
}

export default UsersPermissions;
